import ImageMenu from "./img_menu/img_menu";
import TextMenu from "./text_menu/text_menu";
import EmojiMenu from "./emoji_menu/emoji_menu";
import StickerMenu from "./sticker_menu/sticker_menu";

import "./object_menu_desktop.scss";

import { Stack, Typography, Divider, Box, Button, IconButton, Tabs, Tab, TabList, TabPanel, Tooltip } from "@mui/joy";

import { AddPhotoAlternate, AddReaction, AddShoppingCart, Download, Edit, Fullscreen, FullscreenExit, Save, TextFields } from "@mui/icons-material";
import { useEffect, useState } from "react";
import Tutorial from "../../global_components/tutorial";

export default function ObjectMenuDesktop(props: {
	mugTitle?: string;
	mugDescription?: string;
	bgImage?: any;
	index?: number | null;
	projectId: string;
	isFullscreen: boolean;
	handleStep: (value: number | null) => void;
	handleEditTitle: () => void;
	handleSaveProject: (toCart: boolean) => void;
	handleDownloadProject: () => void;
	handleMenuLoading: (value: [boolean, string]) => void;
	handleFullscreen: () => void;
}) {
	const [title, setTitle] = useState(props.mugTitle ?? "Minha Caneca");
	const [, setDescription] = useState(props.mugDescription ?? "");

	useEffect(() => {
		setTitle(props.mugTitle);
		setDescription(props.mugDescription);
	}, [props]);

	return (
		<Stack sx={{ height: "100vh", maxHeight: "100vh", width: "30vw", borderRight: "1px solid var(--joy-palette-neutral-200)" }} divider={<Divider />}>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					backgroundColor: "white",
					justifyContent: "space-evenly",
					alignContent: "center",
					// width: "30vw",
					// height: "10vh",
				}}
			>
				<Tutorial
					tutorialText={
						"Quando estiver satisfeito com sua criação, pode escolher entre salvar em sua coleção ou adicioná-la direto ao carrinho."
					}
					open={props.index === 3}
					handleSteps={props.handleStep}
					showNextBtn={false}
					complete={100}
				>
					{/* <Box
				sx={{
					display: "flex",
					justifyContent: "space-evenly",
					padding: "1rem 0",
				}}
			>
				<Button
					variant="outlined"
					sx={{ width: "25%" }}
					color="secondary"
					onClick={() => props.handleSaveProject(false)}
				>
					<Save />
					Salvar
				</Button>
				<Button
					variant="outlined"
					sx={{ width: "25%" }}
					color="secondary"
					onClick={() => props.handleDownloadProject()}
				>
					<Download />
					Baixar
				</Button>
				<Button variant="outlined" sx={{ width: "35%" }} onClick={() => props.handleSaveProject(true)}>
					<AddShoppingCart />
					Adicionar ao Carrinho
				</Button>
			</Box> */}
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-evenly",
							padding: "1rem 0",
						}}
					>
						<Tooltip title="Salvar" color="primary" variant="outlined">
							<IconButton
								variant="outlined"
								// sx={{ width: "25%" }}
								color="primary"
								onClick={() => props.handleSaveProject(false)}
							>
								<Save />
							</IconButton>
						</Tooltip>
						<Tooltip title="Baixar" color="primary" variant="outlined">
							<IconButton
								variant="outlined"
								// sx={{ width: "25%" }}
								color="primary"
								onClick={() => props.handleDownloadProject()}
							>
								<Download />
							</IconButton>
						</Tooltip>
						<Tooltip title="Adicionar ao carrinho" color="primary" variant="outlined">
							<IconButton
								variant="outlined"
								color="primary"
								// sx={{ width: "35%" }}
								onClick={() => props.handleSaveProject(true)}
							>
								<AddShoppingCart />
							</IconButton>
						</Tooltip>
					</Box>
				</Tutorial>
			</Box>

			<Tabs aria-label="Vertical tabs" sx={{ height: "80vh" }} defaultValue={0} orientation="vertical">
				<Tutorial
					tutorialText={
						"Aqui você pode navegar entre as abas do editor para personalizar sua caneca!\nNa aba 'Texto', por exemplo, você pode adicionar qualquer texto que escrever, assim como selecionar a fonte e cor do seu texto. "
					}
					open={props.index === 0}
					handleSteps={props.handleStep}
					showPreviousBtn={false}
					direction="right"
					complete={25}
				>
					<TabList
						id="tabList"
						sx={{ backgroundColor: "var(--joy-palette-neutral-100)", justifyContent: "space-between" }}
					>
						<Box sx={{ gap: "0.5em" }}>
							<Tooltip title="Imagem" placement="right" arrow variant="plain" color="primary">
								<Tab className="tab" color="primary">
									<AddPhotoAlternate />
								</Tab>
							</Tooltip>
							<Tooltip title="Texto" placement="right" arrow variant="plain" color="primary">
								<Tab className="tab" color="primary">
									<TextFields />
								</Tab>
							</Tooltip>
							<Tooltip title="Emoji" placement="right" arrow variant="plain" color="primary">
								<Tab className="tab" color="primary">
									<AddReaction />
								</Tab>
							</Tooltip>
							<Tooltip title="Figurinhas" placement="right" arrow variant="plain" color="primary">
								<Tab className="tab" color="primary">
									<span className="material-symbols-rounded">note_stack_add</span>
								</Tab>
							</Tooltip>
						</Box>

						<Box className="fullscreen" onClick={props.handleFullscreen}>
							{
								props.isFullscreen === false &&
								<Tooltip title="Tela cheia(F11)" placement="right" arrow variant="plain" color="primary">
									<Fullscreen color="primary" sx={{ width: "2em", height: "2em" }} />
								</Tooltip>
							}
							{
								props.isFullscreen === true &&
								<Tooltip title="Sair da tela cheia (Esc)" placement="right" arrow variant="plain" color="primary">
									<FullscreenExit color="primary" sx={{ width: "2em", height: "2em" }} />
								</Tooltip>
							}
						</Box>
					</TabList>
				</Tutorial>
				<TabPanel className="tabPanel" value={0}>
					<ImageMenu handleMenuLoading={props.handleMenuLoading} />
				</TabPanel>
				<TabPanel className="tabPanel" value={1}>
					<TextMenu />
				</TabPanel>
				<TabPanel className="tabPanel" value={2}>
					<EmojiMenu />
				</TabPanel>
				<TabPanel className="tabPanel" value={3}>
					<StickerMenu />
				</TabPanel>
			</Tabs>


			<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexGrow: "1", backgroundColor: "white" }}>
				<Tooltip title="Editar título" variant="outlined" color="primary">
					<Typography level="h3" sx={{ textAlign: "center" }} onClick={() => props.handleEditTitle()}>
						{title || "Nomeie sua caneca"}
					</Typography>
				</Tooltip>
			</Box>


		</Stack>
	);
}
