import React, { createContext, useContext, useEffect, useState } from "react";
import { fetchUserAttributes, fetchAuthSession } from "aws-amplify/auth";

const UserContext = createContext(null);

export function UserProvider({ children }) {
	const [user, setUser] = useState(null);
	const [accessToken, setAccessToken] = useState("");

	async function getToken() {
		try {
			const { accessToken } = (await fetchAuthSession()).tokens ?? {};
			let token = accessToken?.toString();
			return token;
		} catch (err) {
			console.error(err);
		}
	}

	async function userToken() {
		const token = await getToken();
		setAccessToken(token ?? "");
		sessionStorage.setItem("userToken", token);
		return token;
	}

	useEffect(() => {
		fetchUserAttributes()
			.then((attr) => {
				// console.log("fetchUserAttributes: ", attr);
				sessionStorage.setItem("userID", attr.sub);
				setUser(attr);
			})
			.catch((err) => {
				console.error("Usuário não logado!");
			});

		userToken();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <UserContext.Provider value={{ user, setUser, accessToken, setAccessToken }}>{children}</UserContext.Provider>;
}
export function useUser() {
	return useContext(UserContext);
}
