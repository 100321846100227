import "./topLayout.scss";
import fizzLogo from "../assets/logozz-white.png";
import ShoppingCart from "../components/global_components/ShoppingCart";

import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import LoginMenu from "../components/login_menu/LoginMenu";
import { useUser } from "../providers/UserInfo";
import { useCart } from "../providers/cartProducts";
import MobileDrawerMenu from "../components/login_menu/MobileDrawerMenu";
import { ModalLoading } from "../components/global_components/Modal";

export default function TopLayout() {
	const [isMobile, setIsMobile] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [cartQuantity, setCartQuantity] = useState<number>(0);
	const [, setContinueProject] = useState(JSON.parse(sessionStorage.getItem("localProject")));
	const user = useUser();
	const nav = useNavigate();
	const pathName = useLocation();
	const cart = useCart();
	const { projectID } = useParams();

	const handleResize = () => {
		setIsMobile(window.innerWidth <= 768 ? true : false);
	};

	useEffect(() => {
		handleResize();
		setContinueProject(JSON.parse(sessionStorage.getItem("localProject")))

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		setCartQuantity(cart.cartList?.length ?? 0);
	}, [cartQuantity, cart]);

	useEffect(() => {
		switch (pathName.pathname) {
			case "/colecao":
				nav(`/colecao/${user.user?.name}`, { replace: true });
				break;
		}
	}, [user, nav, pathName.pathname]);

	useEffect(() => {
		if (sessionStorage.getItem("criar")) {
			setLoading(true)
			setTimeout(() => {
				nav(`/criar/${sessionStorage.getItem("criar")}`);
				setLoading(false);
				sessionStorage.removeItem("criar");
			}, 500)
		}
		if (sessionStorage.getItem("carrinho")) {
			setLoading(true)
			setTimeout(() => {
				let cartItem = JSON.parse(sessionStorage.getItem("cartItem"))
				cart.addToCart(cartItem)
				nav(`/carrinho`);
				setLoading(false)
				sessionStorage.removeItem("carrinho");
				sessionStorage.removeItem("cartItem");
			}, 500)
		}
	}, [])

	function handleMenu() {
		const continueProj = sessionStorage.getItem('localProject')
		if (isMobile) {
			return <MobileDrawerMenu />;
		} else {
			return (
				<aside>
					<Link to="/" className={`tab ${pathName.pathname === "/" ? "active" : ""}`}>
						Home
					</Link>
					<Link
						to={continueProj ? "/criar/convidado" : "/criar"}
						className={`tab ${pathName.pathname === "/criar" ? "active" : ""}`}
					>
						{continueProj ? "Continuar Projeto" : "Criar"}
					</Link>
					<Link to="/colecao" className={`tab ${pathName.pathname === "/colecao" ? "active" : ""}`}>
						Coleção
					</Link>
					{user.user === null && (
						<Link to={"/login"} className={`tab ${pathName.pathname === "/login" ? "active" : ""}`}>
							Login
						</Link>
					)}
					{user.user != null && <LoginMenu />}
					<ShoppingCart
						className={`tab ${pathName.pathname === "/carrinho" ? "active" : ""}`}
						iconColor="white"
						productQuantity={cartQuantity}
					/>
				</aside>
			);
		}
	}

	if (pathName.pathname === "/" || pathName.pathname === `/criar/${projectID}` || pathName.pathname === "/criar") {
		return <Outlet />;
	}

	return (
		<div className="topLayout">
			<nav>
				<div className="wrapper">
					<img src={fizzLogo} alt="fizz logo" onClick={() => nav("/")} style={{ cursor: "pointer", height: "2.5em" }} />
					{handleMenu()}
				</div>
			</nav>
			<div style={{ display: "flex", justifyContent: "center" }}>
				<Outlet />
			</div>
			<ModalLoading openModal={loading} modalTitle="Carregando..." />
		</div>
	);
}
