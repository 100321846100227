import cattoMug from "../../../assets/cattoMug.png";

import './FinalStep.scss';
import { Box, Grid, Typography } from "@mui/joy";

import CardPreview from '../../global_components/CardPreview';
import StepBody from "../StepBody";
import { useCart } from "../../../providers/cartProducts";
import Slider from "../../global_components/Slider";



export default function FinalStep(props: {
    handleStepper: (value: number | null) => void
    handleFinalization: () => void
}) {
    const cart = useCart()

    function handleProducts() {
        return (
            cart.cartList.map((data) => {
                return (
                    <div key={data.Id}>
                        <CardPreview
                            id={data.Id}
                            imgSrc={cattoMug}
                            title={data.Title}
                            showFavorite={false}
                            menu={false}

                            height="300px"
                            width="300px"
                        />
                    </div>
                )
            })
        )
    }
    return (
        <StepBody handleStepper={props.handleStepper} handleFinalization={props.handleFinalization} step={3}>
            <Grid md={12} xs={12} container alignItems="center">
                <Grid md={12} xs={12}>
                    <Typography level="h4">Resumo do pedido</Typography>
                </Grid>
                {
                    cart.cartList.length > 1 &&
                    <Grid md={6} xs={12} display="flex" justifyContent="center">
                        <Box sx={{ position: "relative", maxWidth: "320px"}}>
                            <Slider>
                                {handleProducts()}
                            </Slider>
                        </Box>
                    </Grid>

                }
                {
                    cart.cartList.length <= 1 &&
                    <Grid md={6} xs={8}>
                        <CardPreview
                            imgSrc={cattoMug}
                            menu={false}
                            showFavorite={false}
                            id="111"
                            title="teste"

                            height="300px"
                            width="300px"
                        />
                    </Grid>
                }
                <Grid md={6} xs={12}>
                    <Box className="infoWrapper">
                        <Box className="infoBox">
                            <Box className="info">
                                <Typography level='title-lg' sx={{ marginBottom: "0.5em" }}>Contato:</Typography>
                                <Typography level="title-sm">Tel.: {"(xx) 9xxxx-xxxx"}</Typography>
                                <Typography level="title-sm">E-mail: example@gmail.com</Typography>
                            </Box>
                        </Box>


                        <Box className="infoBox">
                            <Box className="info">
                                <Typography level='title-lg' sx={{ marginBottom: "0.5em" }}>Endereço:</Typography>
                                <Typography level="title-sm">CEP: 00000-000</Typography>
                                <Typography level="title-sm">Rua: aaaaaaaaa</Typography>
                                <Typography level="title-sm">Nº: 999</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </StepBody>
    )
}