import { v1 as uuidV1 } from "uuid";
import { ElementsContext } from "../../../../../pages/create/create";
import { useContext } from "react";
import Element from "../../../../../interfaces/element";
import { background_data } from "./background_data";
import ChangeSetSingleton from "../../../texture_renderer/ChangeSetSingleton";

export default function BackgroundMenu() {
	const { elements, setElements } = useContext(ElementsContext);
	const changeBackground = (evt: any) => {
		let style = evt.target.currentStyle || window.getComputedStyle(evt.target);
		let imgSrc = style.backgroundImage.slice(4, -1).replace(/"/g, "");
		const newElement: Element = {
			id: uuidV1(),
			media: {
				src: imgSrc,
			},
			type: "background",
			x: 300,
			y: 300,
			scaleX: 0.3,
			scaleY: 0.3,
			shown: true,
			rotation: 0,
		};

		setElements((els) => {
			ChangeSetSingleton.get().addHistory([newElement, ...els.filter((x) => x.type !== "background")]);
			return [newElement, ...els.filter((x) => x.type !== "background")];
		});
	};

	return (
		<div>
			<div
				style={{
					width: "100%",
					display: "flex",
					flexWrap: "wrap",
					gap: "0.5em",
					paddingRight: "0.5em",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				{background_data.map((png, i) => {
					return (
						<div
							key={i}
							style={{
								background: `url("/backgrounds/${png}")`,
								width: 400,
								height: 160,
								backgroundSize: "cover",
								cursor: "pointer",
								borderRadius: "0.5em",
								border: "solid 3px var(--outline)",
							}}
							onClick={changeBackground}
						/>
					);
				})}
			</div>
		</div>
	);
}
