import cattoMug from "../../../assets/cattoMug.png";
// import InputMask from "react-input-mask";

import "./CardStep.scss";

import { RemoveShoppingCart } from "@mui/icons-material";
import {
    Grid,
    Typography,
    Button,
    Stack,
    Divider,
    Input,
    Skeleton,
} from "@mui/joy";
import { ModalAlert } from "../../global_components/Modal";
import { useEffect, useState } from "react";
// import { deliveryData } from "../../../pages/user/cart/Data";
import { useCart } from "../../../providers/cartProducts";
import StepBody from "../StepBody";
import { cartList } from "../../../interfaces/cartInterface";

export default function CartStep(props: {
    handleStepper: (value: number) => void
}) {
    const cart = useCart();
    const [modalClear, setModalClear] = useState<boolean>(false);
    const [modalRemove, setModalRemove] = useState<boolean>(false);
    const [toRemove, setToRemove] = useState(null);
    // const [deliveryValue, setDeliveryValue] = useState(null);
    // const [selectedCompany, setSelectedCompany] = useState(null);
    // const [company, setCompany] = useState([]);
    // const [cep, setCep] = useState("");
    // const [cepDeliveryInfo, setCepDeliveryInfo] = useState("");
    // const [totalItems, setTotalItems] = useState(0);
    // const [total, setTotal] = useState(0);
    const [shopList, setShopList] = useState<cartList[]>([])
    // const [totalAmount, setTotalAmount] = useState<number>(0);
    const [totalValue, setTotalValue] = useState<number>(0);

    // useEffect(() => {
    //     setTotalItems(
    //         shop.reduce((accumulator, currentValue) => {
    //             return accumulator + currentValue.price * currentValue.quantity;
    //         }, 0)
    //     );
    // }, [shop]);

    // useEffect(() => {
    //     setCompany(deliveryData);
    // }, []);

    // useEffect(() => {
    //     setTotal(totalItems + (deliveryValue?.price ? deliveryValue?.price : 0));
    // }, [deliveryValue, totalItems]);


    function handleCloseRemoveModal() {
        setToRemove(null);
        setModalRemove(false);
    };

    function handleCloseClearModal() {
        setModalClear(false);
    };

    function handleRemoveItem() {
        cart.removeItem(toRemove.Id)
        setModalRemove(false);
    }

    function handleClearCart() {
        cart.clearCart()
        setModalClear(false);
    }

    function handleQntChange(index, quantity, item) {
        if(quantity === 0) {
            setToRemove(item)
            setModalRemove(true)
        } else {
            cart.itemQuantity(index, quantity)
        }
    }

    async function handleBuildCartList() {
        const newList = cart.cartList.map((shopItem) => {
            const newProject = cart.project.find((projectItem) => shopItem.projectId === projectItem?.Id);
            // const newProduct = product.find((productItem) => shopItem?.productId === productItem.Id);

            return {
                Id: newProject?.Id,
                Title: newProject?.Title,
                Model: cart.product?.Description,
                Quantity: shopItem?.quantity,
                UnitPrice: cart.product?.Price,
                AvailableUnit: cart.product?.AvailableQuantity
            };
        });
        let newValue = newList.reduce((agg, item) => (agg + (item.UnitPrice * item.Quantity)), 0);
        setShopList([...newList]);
        setTotalValue(newValue);
    };

    useEffect(() => {
        handleBuildCartList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalValue, cart.cartList, cart.loading, cart.product, cart.project]);



    return (
        <StepBody handleStepper={props.handleStepper} step={0} totalValue={totalValue / 100} >
            <Grid md={12} xs={12} sx={{ display: "flex", justifyContent: "space-between", marginBottom: "5em" }}>
                <Typography level="h2">Produtos</Typography>
                <Button variant="outlined" color="danger" onClick={() => setModalClear(true)} size="sm">
                    <RemoveShoppingCart />
                    Limpar Carrinho
                </Button>
            </Grid>
            <Grid md={12} xs={12}>
                <Stack divider={<Divider sx={{ margin: "1em 0" }} />}>
                    {
                        cart.loading === true &&
                        <Grid container md={12} xs={12} spacing={2} sx={{ display: "flex", flexGrow: "1", justifyContent: "space-between" }}>
                            <Grid md={12}>
                                <Skeleton animation="wave" variant="rectangular" width="100%" height="200px" sx={{ borderRadius: "1rem" }} />
                            </Grid>
                            <Grid md={12}>
                                <Skeleton animation="wave" variant="rectangular" width="100%" height="200px" sx={{ borderRadius: "1rem" }} />
                            </Grid>
                        </Grid>
                    }
                    {cart.loading !== true && shopList.map((item, index) => 
                         (
                            <Grid
                                key={item.Id}
                                md={12}
                                xs={12}
                                container
                                spacing={2}
                                sx={{ display: "flex", flexGrow: "1", justifyContent: "space-between" }}
                            >
                                <Grid md={2} xs={12} container alignItems="center">
                                    <img className="mugImage" src={cattoMug} alt="img" />
                                </Grid>
                                <Grid md={10} xs={12} container alignItems="center">
                                    <Grid md={5} xs={12}>
                                        <Typography level="h4">{item?.Title}</Typography>
                                        <Typography level="body-sm">{item.Model}</Typography>
                                    </Grid>
                                    <Grid md={1} xs={2} sx={{ gap: "0.5em", marginRight: "0.5em" }}>
                                        <Typography level="body-md" sx={{ marginBottom: "0.5em" }}>Qntd.</Typography>
                                        <Button
                                            fullWidth
                                            onClick={() => {
                                                cart.itemQuantity(index, item.Quantity + 1);
                                            }}
                                        >
                                            +
                                        </Button>
                                        <Input
                                            type="number"
                                            className="qtdInput"
                                            value={item.Quantity}
                                            onChange={(evt) => handleQntChange(index, +evt.target.value, item)}
                                            variant="plain"
                                            slotProps={{ input: { style: {overflow: 'visible', textOverflow: 'clip'} } }}
                                            sx={{fontSize: 16, paddingInline: "0", overflow: 'visible'}}
                                        />
                                        <Button
                                            fullWidth
                                            onClick={() => {
                                                handleQntChange(index, item.Quantity - 1, item)
                                            }}
                                        >
                                            -
                                        </Button>
                                    </Grid>
                                    <Grid md={2} xs={3}>
                                        <Typography level="title-md">Unitário</Typography>
                                        <Typography>R$ {(item.UnitPrice / 100)?.toFixed(2)}</Typography>
                                    </Grid>
                                    <Grid md={2} xs={3}>
                                        <Typography level="title-md">Total</Typography>
                                        <Typography>R$ {((item.UnitPrice * item.Quantity) / 100)?.toFixed(2)}</Typography>
                                    </Grid>
                                    <Grid md={1} xs={3}>
                                        <Button
                                            variant="outlined"
                                            color="danger"
                                            onClick={() => {
                                                setToRemove(item);
                                                setModalRemove(true);
                                            }}
                                        >
                                            Remover
                                        </Button>
                                    </Grid>

                                </Grid>
                            </Grid>
                        ))
                    }
                </Stack>
            </Grid>


            <ModalAlert
                openModal={modalRemove}
                title="Deseja remover este item?"
                content={toRemove?.Title}
                confirmTextColor="danger"
                confirmText="Remover"
                cancelText="Cancelar"
                handleConfirmation={handleRemoveItem}
                handleCancelOperation={handleCloseRemoveModal}
                handleCloseModal={handleCloseRemoveModal}
            />

            <ModalAlert
                openModal={modalClear}
                title="Limpar Carrinho?"
                content="Deseja remover todos os itens do carrinho?"
                confirmTextColor="danger"
                confirmText="Limpar Carrinho"
                cancelText="Cancelar"
                handleConfirmation={handleClearCart}
                handleCancelOperation={handleCloseClearModal}
                handleCloseModal={handleCloseClearModal}
            />
            {/* <Stack spacing={2}>
					<Box>
						<Typography level="title-lg">Total:</Typography>
						<Typography>R$ {totalItems?.toFixed(2)}</Typography>
						<Divider sx={{margin: "1em 0"}} />
						<Typography level="body-sm" sx={{marginBottom: "1em"}}>
							*O valor acima refere-se ao valor da caneca. Seu atendimento será continuado através do e-mail ou telefone
							que você disponibilizar, e, por lá será informado o valor do frete!
						</Typography>

						{
                            deliveryValue &&
                            <Box sx={{ marginBottom: "2em" }}>
                                <Typography level="title-lg">Total com Frete:</Typography>
                                <Typography>R$ {total?.toFixed(2)}</Typography>
                            </Box>
                        }
					</Box>
					{
                        deliveryValue &&
                        <Divider />
                    }
                    <Box sx={{ display: "flex", gap: "1em", alignItems: "center" }}>
                        <FormControl>
                            <FormLabel>Calcule o frete do seu pedido</FormLabel>
                            <InputMask mask="99999-999" value={cep} onChange={(evt) => setCep(evt.target.value)} maskChar=" ">
                                {
                                    //@ts-ignore
                                    () => (<Input value={cep} placeholder="00000-000" />)
                                }
                            </InputMask>
                            <FormHelperText><a className="cepLink" target="_blank" href="https://buscacepinter.correios.com.br/app/endereco/index.php" rel="noreferrer">Não sei meu CEP</a></FormHelperText>
                        </FormControl>
                        <Button sx={{ height: "fit-content" }} size="sm" onClick={() => setCepDeliveryInfo(cep)}>Ok</Button>
                    </Box>
                    {
                        cepDeliveryInfo &&
                        company.map((item) => {
                            return (
                                <Stack key={item.id}>
                                    <Box className={(`company ${selectedCompany === item.Id ? "active" : ""}`)} sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", padding: "0.5em" }}>
                                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                                            <Typography><Typography level="title-md">Empresa: </Typography>{item.company}</Typography>
                                            <Typography><Typography level="title-md">Valor: </Typography>R$ {(item.price)?.toFixed(2)}</Typography>
                                            <Typography><Typography level="title-md">Prazo: </Typography>{item.delivery}</Typography>
                                        </Box>
                                        <Radio variant="outlined" color="primary" onChange={() => { setDeliveryValue(item); setSelectedCompany(item.Id) }} checked={item.Id === selectedCompany} />
                                    </Box>
                                </Stack>
                            )
                        })
                    }
					<Button onClick={() => nav("preBuy", { state: { key: "value" } })} color="primary">Comprar</Button>
					<Button onClick={() => props.handleStepper(+1)} color="primary">
						Avançar
					</Button>
					<Button color="secondary" onClick={() => nav("/colecao")}>
						Continuar Comprando
					</Button>
				</Stack> */}
        </StepBody>

    );
}
