import React, { useContext, useState } from "react";
import Element from "../../../../../interfaces/element";
import { ElementsContext } from "../../../../../pages/create/create";
import { v1 as uuidV1 } from "uuid";
import InputFileUpload from "../../../../global_components/InputFileUpload";
import { Stack } from "@mui/joy";
import UploadWizard from "../upload_wizard/upload_wizard";

export default function UploadMenu(props: { handleMenuLoading: (value: [boolean, string]) => void }) {
	const [img, setImg] = useState<File | null>(null);

	const loadPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files[0];
		if (file) {
			const url = URL.createObjectURL(file);
			console.log(url);
			setImg(file);
		}
	};

	const handleClear = ()=>{
		setImg(null)
	}

	return (
		<div className="media">
			<Stack className="imgUpload" spacing={2}>
				<InputFileUpload onChange={loadPhoto} />
			</Stack>
			<UploadWizard image={img} handleClose={handleClear} />
		</div>
	);
}
