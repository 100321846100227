import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';



const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 65000
});

apiClient.defaults.headers.post['Content-Type'] = 'application/json';
apiClient.interceptors.request.use(async (req) => {
    const info = await fetchAuthSession();
    req.headers.Authorization = 'Bearer ' + info.tokens.accessToken.toString();
    req.headers['Content-Type'] = "application/json"
    req.url = req.url.replaceAll("@userID", info.userSub);
    return req;
})

export default apiClient;



