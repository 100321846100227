import "./upload_wizard.scss";
import "cropperjs/dist/cropper.css";
import { CircularProgress, Divider, Grid, Stack, Typography } from "@mui/joy";
import { Cropper, ReactCropperElement } from "react-cropper";
import { CircleOutlined, CropSquare, FavoriteBorder, SquareOutlined, StarBorder } from "@mui/icons-material";
import { Media } from "../../../../../interfaces/mediaInterface";
import { ModalAlert, ModalBig, ModalLoading } from "../../../../global_components/Modal";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../../../providers/UserInfo";
import { v1 as uuidV1 } from "uuid";
import CardButton from "../../../../global_components/CardButton";
import cartoon from "../../../../../assets/cartoon.png";
import React, { useContext, useEffect, useRef, useState } from "react";
import removeBG from "../../../../../assets/removeBG.jpg";
import { Effects, ImageEffects, postImageEffect } from "../../../../../services/imageService";
import { newMedia } from "../../../../../services/mediaService";
import { ElementsContext, ProjectContext } from "../../../../../pages/create/create";
import Element from "../../../../../interfaces/element";
import ChangeSetSingleton from "../../../texture_renderer/ChangeSetSingleton";

export default function UploadWizard(props: { image: File | null; handleClose: () => void }) {
	const [imageModal, setImageModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [modal, setModal] = useState(false);
	const [imageURL, setImageURL] = useState<string>();
	const [checkCartoon, setCheckCartoon] = useState(false);
	const [checkRBG, setCheckRBG] = useState(false);
	const [format, setFormat] = useState<"star" | "hearth" | "square" | "roundSquare" | "heart" | "circle">("square");
	const [modalLogin, setModalLogin] = useState(false);
	const user = useUser();
	const nav = useNavigate();

	const { elements, setElements } = useContext(ElementsContext);
	const { project } = useContext(ProjectContext);

	const cropperRef = useRef<ReactCropperElement>(null);
	// const onCrop = () => {
	// 	const cropper = cropperRef.current?.cropper;
	// 	console.log(cropper.getCroppedCanvas().toDataURL());
	// };

	useEffect(() => {
		setImageModal(props.image != null);
		if (props.image != null) setImageURL(URL.createObjectURL(props.image));
	}, [props.image]);

	const addEffects = async () => {
		let effectList: Effects[] = [];

		if (checkCartoon) effectList.push(Effects.CARTOONIFY);
		if (checkRBG) effectList.push(Effects.REMOVE_BACKGROUND);

		const cropper = cropperRef.current.cropper;
		const b64 = cropper.getCroppedCanvas().toDataURL();

		if (effectList.length === 0) return b64;

		if (user.user == null) {
			setModal(true);
			return;
		}

		const base64Image = b64.split(",")[1];
		const formatImage = b64.split(",")[0];

		return await postImageEffect({
			file: base64Image,
			effect: effectList,
		} as ImageEffects)
			.then((response) => {
				const finalImage = `${formatImage},${response["file"]}`;
				return finalImage;
			})
			.catch((x) => {
				console.error(x);

				throw x;
			});

		// props.handleMenuLoading([true, "Carregando imagem"]);
		// 	.finally(() => {
		// 		props.handleMenuLoading([false, "Carregando imagem"]);
		// 	});
	};

	const uploadPhoto = async (b64: string) => {
		const base64Image = b64.split(",")[1];
		const formatImage = b64.split(",")[0];
		const imgType = formatImage.split("/")[1].split(";")[0];

		const media: Media = {
			MediaId: uuidV1(),
			MediaFormat: imgType as "svg" | "jpg" | "jpeg" | "png" | "gif" | "webp",
			MediaType: "image",
			Media: base64Image,
			Metadata: "{}",
		};

		await newMedia(project.Id, media).then((x) => {
			const url = x.PreSignedUrl[Object.keys(x.PreSignedUrl)[0]];
			//const url = 'https://readrange.com/wp-content/uploads/2023/10/RR-hero-1-768x480.jpeg'

			const newElement: Element = {
				id: media.MediaId,
				media: {
					src: url,
				},
				x: 300,
				y: 300,
				type: format,
				zIndex: elements.length + 1,
				scaleX: 1,
				scaleY: 1,
				shown: true,
				rotation: 0,
			};

			setElements((els) => {
				ChangeSetSingleton.get().addHistory([newElement, ...els]);
				return [newElement, ...els];
			});
			props.handleClose();
		});
	};

	const handlePhotoAdd = () => {
		//loading in

		//aplicar efeitos se necessário
		setLoading(true);
		addEffects()
			.then((b64: string) => {
				uploadPhoto(b64).finally(() => setLoading(false));
			})
			.catch((err) => {
				setLoading(false);
			});

		//fazer upload da imagem

		//adicionar elemento

		//loading out
	};

	return (
		<>
			<ModalAlert
				openModal={modal}
				title="Ops!"
				content={
					"Para aplicar efeitos nas imagens você precisar entrar em sua conta.\nCadastre-se agora, ou faça login!"
				}
				cancelText="Cadastrar"
				cancelTextColor="secondary"
				confirmText="Entrar"
				confirmTextColor="primary"
				handleCloseModal={() => setModal(false)}
				handleCancelOperation={() => nav("/login")}
				handleConfirmation={() => nav("/login")}
			/>
			<ModalBig
				onClose={props.handleClose}
				open={imageModal}
				modalTitle="Editar e recortar imagem"
				buttonTitle="Adicionar ao projeto"
				onBtnClick={handlePhotoAdd}
			>
				<Grid container sx={{ flexGrow: 1, overflow: "hidden" }}>
					<Grid xs={6}>
						{imageURL && (
							<Cropper
								src={imageURL}
								viewMode={0}
								style={{ height: "100%", width: "96%" }}
								// Cropper.js options
								initialAspectRatio={1}
								modal={true}
								guides={false}
								autoCropArea={1}
								crop={() => {}}
								ref={cropperRef}
							/>
						)}
					</Grid>
					<Grid xs={6} container spacing={2} sx={{ overflowY: "auto", maxHeight: "100%" }}>
						<Stack spacing={1}>
							<Grid xs={12} container spacing={2}>
								<Grid xs={12}>
									<Typography level="title-md">Efeitos especiais</Typography>
								</Grid>
							</Grid>
							<Grid xs={12} container spacing={2}>
								<Grid xs={6}>
									<CardButton
										image={removeBG}
										text="Remover fundo"
										status={checkRBG}
										onChange={(status) => setCheckRBG(status)}
									/>
								</Grid>
								<Grid xs={6}>
									<CardButton
										image={cartoon}
										text="Cartoonizar"
										status={checkCartoon}
										onChange={(status) => setCheckCartoon(status)}
									/>
								</Grid>
							</Grid>

							<Grid xs={12} container spacing={2}>
								<Grid xs={12}>
									<Divider sx={{ marginBottom: "1em" }} />
									<Typography level="title-md">Formato da imagem</Typography>
								</Grid>
							</Grid>
							<Grid xs={12} container spacing={2}>
								<Grid xs={4}>
									<CardButton
										icon={<SquareOutlined />}
										text="Padrão"
										status={format === "square"}
										onChange={(status) => setFormat("square")}
									/>
								</Grid>
								<Grid xs={4}>
									<CardButton
										icon={<CropSquare />}
										text="Arredondado"
										status={format === "roundSquare"}
										onChange={(status) => setFormat("roundSquare")}
									/>
								</Grid>
								<Grid xs={4}>
									<CardButton
										icon={<CircleOutlined />}
										text="Circulo"
										status={format === "circle"}
										onChange={(status) => setFormat("circle")}
									/>
								</Grid>
								<Grid xs={4}>
									<CardButton
										icon={<FavoriteBorder />}
										text="Coração"
										status={format === "heart"}
										onChange={(status) => setFormat("heart")}
									/>
								</Grid>
								<Grid xs={4}>
									<CardButton
										icon={<StarBorder />}
										text="Estrela"
										status={format === "star"}
										onChange={(status) => setFormat("star")}
									/>
								</Grid>
							</Grid>
						</Stack>
					</Grid>
				</Grid>
			</ModalBig>
			<ModalAlert
				openModal={modalLogin}
				title="Ops!"
				content={
					"Para aplicar efeitos nas imagens você precisar entrar em sua conta.\nCadastre-se agora, ou faça login!"
				}
				cancelText="Cadastrar"
				cancelTextColor="secondary"
				confirmText="Entrar"
				confirmTextColor="primary"
				handleCloseModal={() => setModalLogin(false)}
				handleCancelOperation={() => nav("/login")}
				handleConfirmation={() => nav("/login")}
			/>
			<ModalLoading openModal={loading} modalTitle="Aplicando efeitos e salvando imagem" />{" "}
		</>
	);
}
