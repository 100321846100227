import apiClient from "../plugins/apiClient";

import CartInterface from "../interfaces/cartInterface";

export async function getCart() {
    try {
        const response = await apiClient.get(`/cart`)
        return response.data
    } catch(error) {
        console.error(error)
    }
}

export async function newCart(cart: CartInterface) {
    try {
        const response = await apiClient.post(`/cart`, cart)
        return response.data
    } catch(error) {
        console.error(error)
    }
}

export async function deleteCart() {
    try {
        const response = await apiClient.delete(`/cart`)
        return response.data
    } catch(error) {
        console.error(error)
    }
}