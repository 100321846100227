import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { newCart, getCart, deleteCart } from "../services/cartService";

import CartInterface, { cartItem } from "../interfaces/cartInterface";
import collectionCard from "../interfaces/collectionCard";
import Product from "../interfaces/productInterface";
import { getProductByID } from "../services/productService";
import { getProjectByID } from "../services/projectService";

const CartProductsContext = createContext(null);

export function CartProductsProvider({ children }) {

	/*
	cartList -> constante que armazena a lista de itens do carrinho de acordo com a interface CartInterface["items"]
	*/
	const [cartList, setCartList] = useState<CartInterface["items"]>([]);
	const [project, setProject] = useState<collectionCard[]>([]);
	const [product, setProduct] = useState<Product>();
	const [loading, setLoading] = useState<boolean>(false);
	/*
	variável responsável por checar os items (projetos) já existentes na cartList
	*/
	const [loadedItemIds, setLoadedItemIds] = useState<string[]>([]);
	/*
	variável para lidar com reset do timeout 
	*/
	const timerRef = useRef(null);

	/*
	addToCart -> função usada para adicionar um projeto ao carrinho, utilizada nos botões de "Adicionar ao carrinho"
	a função chama também a API newCart que faz um POST do novo carrinho, já fazendo o tratamento caso o usuário adicione o mesmo item ao carrinho
	aumentando sua quantidade
	*/
	async function addToCart(item: collectionCard) {
		const itemIndex = cartList?.findIndex((x) => x.projectId === item.Id);
		let updatedCartList: cartItem[];

		if (itemIndex > -1) {
			cartList[itemIndex].quantity++;
			updatedCartList = [...cartList];

		} else {
			const newItem: cartItem = {
				price: 2500,
				productId: "1ec6a361-9505-412e-a95b-4fc2cb4abe3c",
				projectId: item.Id,
				quantity: 1,
			};

			updatedCartList = [...cartList, newItem];
		}
		setCartList(updatedCartList);

		await newCart({
			items: updatedCartList,
			totalAmount: cartList.reduce((agg, item) => (agg + (item.price * item.quantity)), 0)
		});
	}

	/*
	handleGetCart -> função que chama a API de GET cart quando o usuário loga, graças à sua chamada no useEffect
	Caso vazio então o cartList é setado para vazio, senão ele é populado pela resposta da API
	*/
	async function handleGetCart() {
		setLoading(true);
		let response = await getCart();


		if (!response) {
			setCartList([]);
		} else {
			setCartList(response.items);

			const getItems: collectionCard[] = await Promise.all(
				cartList.map(async (item) => {
					let proj = await getProjectByID(item.projectId);
					return proj;
				})
			);

			setProject((prevProject) => [...prevProject, ...getItems])
	
			let prod = await getProductByID("1ec6a361-9505-412e-a95b-4fc2cb4abe3c");
			setProduct(prod)
		}
		setLoading(false);
	}

	/*
	handleNewProjects -> lida com a atualização da lista do carrinho para carregar projetos novos, sem precisar fazer uma nova chamada
	para cada item que ja existia no carrinho
	*/
	async function handleNewProjects() {
		const newItems = cartList.filter((item) => !loadedItemIds.includes(item.projectId));

		if (newItems.length === 0) return;

		const getNewItems: collectionCard[] = await Promise.all(
			newItems.map(async (item) => {
				let proj = await getProjectByID(item.projectId);
				return proj;
			})
		);

		setProject((prevProject) => [...prevProject, ...getNewItems]);

		setLoadedItemIds((prevIds) => [...prevIds, ...newItems.map((item) => item.projectId)]);
	}

	/*
	clearCart -> função responsável por limpar o carrinho inteiro, usando a DELETE API
	limpa também a cartList para atualizar rapidamente a tela do usuário
	*/
	async function clearCart() {
		await deleteCart();
		setCartList([]);
	}

	/*
	removeItem -> remove um item do carrinho
	filtra todos os items diferentes do Id de projeto passado para função e cria uma nova lista em cartList
	após o filtro a função chama a API de POST para criar um novo carrinho
	*/
	async function removeItem(project: collectionCard["Id"]) {
		let newList: CartInterface["items"] = cartList.filter((item) => item.projectId !== project)
		setCartList([...newList])

		await newCart({
			items: [...newList],
			totalAmount: newList.reduce((agg, item) => (agg + (item.price * item.quantity)), 0)
		})
	}

	/*
	itemQuantity -> lida com as operações de soma e subtração dos botões do carrinho para cada produto
	ela recebe o Id do projeto ao qual ela deverá alterar a quantidade, e recebe o argumento 'quantity' que determina essa quantidade seja pelos botões de + ou - ou pelo input
	uma variável temporária é usada para armazenar o novo valor da lista (questões de react) e depois de 2 segundos é chamada o POST da API para um novo carrinho atualizado
	o settimout também possui uma variável que serve para resetar o tempo do timeout toda vez que a função é chamada, assim evitando chamadas excessivas da API
	*/
	async function itemQuantity(index, quantity: number) {
		let updatedCartQnt: cartItem[] = [];

		cartList[index].quantity = quantity < 0 ? 1 : quantity;

		updatedCartQnt = [...cartList];
		setCartList(updatedCartQnt)

		if (timerRef.current) {
			clearTimeout(timerRef.current);
		}
		timerRef.current = setTimeout(async () => {
			await newCart({
				items: cartList,
				totalAmount: cartList.reduce((agg, item) => agg + (item.price * item.quantity), 0),
			});
		}, 2000);
	}


	useEffect(() => {
		handleGetCart();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
	}, [project, product, cartList, loading]);

	useEffect(() => {
		handleNewProjects();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cartList])


	return (
		<CartProductsContext.Provider value={{ cartList, setCartList, addToCart, clearCart, removeItem, itemQuantity, loading, project, product }}>
			{children}
		</CartProductsContext.Provider>
	);
}

export function useCart() {
	return useContext(CartProductsContext);
}