import { useContext, useState } from "react";
import Element from "../../../../interfaces/element";
import { ElementsContext } from "../../../../pages/create/create";
import { v1 as uuidV1 } from "uuid";
import {
	Button,
	FormControl,
	FormLabel,
	Grid,
	IconButton,
	Slider,
	Textarea,
	ToggleButtonGroup,
	Typography,
	Divider,
} from "@mui/joy";
import ColorPicker from "../../../global_components/ColorPicker";
import FontSelector from "./font_selector/font_selector";
import ChangeSetSingleton from "../../texture_renderer/ChangeSetSingleton";

export default function TextMenu() {
	const { elements, setElements } = useContext(ElementsContext);
	const [textValue, setTextValue] = useState("");
	const [textSize, setTextSize] = useState(100);
	const [stroke, setStroke] = useState("#ffffff");
	const [strokeSize, setStrokeSize] = useState(0);
	const [textAlign, setTextAlign] = useState("center");
	const [fontFamily, setFontFamily] = useState("Roboto");
	const [textColor, setTextColor] = useState("#000000");

	const handleTextSave = (evt: any) => {
		evt.preventDefault();

		const newElement: Element = {
			id: uuidV1(),
			text: {
				value: textValue,
				size: textSize,
				align: textAlign,
				family: fontFamily,
				strokeColor: stroke,
				strokeSize: strokeSize,
				padding: 20,
				color: textColor,
			},
			x: 400,
			y: 400,
			type: "text",
			zIndex: elements.length + 1,
			scaleX: 1,
			scaleY: 1,
			shown: true,
			rotation: 0,
		};

		addNewText(newElement);
		// if(!props["textId"] || props["textId"] === "")
		// else
		// editExistingText(newElement)
	};

	const addNewText = (newElement: Element) => {
		setElements((els) => {
			ChangeSetSingleton.get().addHistory([newElement, ...els]);
			return [newElement, ...els];
		});
	};

	const editExistingText = (edited: Element) => {
		const editIndex = elements.findIndex((x) => x.id === edited.id);
		elements[editIndex] = edited;

		setElements((els)=>[...els, elements[editIndex]]);
	};

	return (
		<div className="text">
			<form className="textFormWrapper" onSubmit={handleTextSave}>
				<Grid container spacing={2} sx={{ flexGrow: 1 }}>
					<Grid xs={12}>
						<FormControl>
							<FormLabel>Conteúdo</FormLabel>
							<Textarea
								name="textValue"
								maxRows={4}
								minRows={2}
								onChange={(evt) => setTextValue(evt.target.value)}
								value={textValue}
							/>
						</FormControl>
					</Grid>

					<Grid xs={12}>
						<Divider sx={{ marginBottom: "1em" }} />
						<Typography>Texto</Typography>
					</Grid>
					<Grid xs={8}>
						<FormControl>
							<FormLabel>Fonte</FormLabel>
							<FontSelector onChange={(evt, value) => setFontFamily(value)} value={fontFamily} />
						</FormControl>
					</Grid>
					<Grid xs={4}>
						<FormControl>
							<FormLabel>Alinhamento</FormLabel>
							<ToggleButtonGroup
								onChange={(evt: any, newValue: string) => {
									setTextAlign(newValue);
								}}
								value={textAlign}
							>
								<IconButton value="left">
									<span className="material-symbols-rounded">format_align_left</span>
								</IconButton>
								<IconButton value="center">
									<span className="material-symbols-rounded">format_align_center</span>
								</IconButton>
								<IconButton value="right">
									<span className="material-symbols-rounded">format_align_right</span>
								</IconButton>
							</ToggleButtonGroup>
						</FormControl>
					</Grid>

					<Grid xs={8}>
						<FormControl>
							<FormLabel>Tamanho</FormLabel>
							<Slider
								name="textSize"
								min={0}
								max={500}
								step={10}
								valueLabelDisplay="auto"
								defaultValue={100}
								onChange={(_, value) => setTextSize(+value)}
								value={textSize}
							/>
						</FormControl>
					</Grid>
					<Grid xs={4}>
						<FormControl>
							<FormLabel>Cor</FormLabel>
							<ColorPicker value={textColor} onChangeComplete={(color) => setTextColor(color)} />
						</FormControl>
					</Grid>

					<Grid xs={12}>
						<Divider sx={{ marginBottom: "1em" }} />
						<Typography>Borda do texto</Typography>
					</Grid>

					<Grid xs={8}>
						<FormControl>
							<FormLabel>Tamanho</FormLabel>
							<Slider
								name="strokeSize"
								min={0}
								max={80}
								step={1}
								valueLabelDisplay="auto"
								defaultValue={0}
								onChange={(_, value) => setStrokeSize(+value)}
								value={strokeSize}
							/>
						</FormControl>
					</Grid>
					<Grid xs={4}>
						<FormControl>
							<FormLabel>Cor</FormLabel>
							<ColorPicker value={stroke} onChangeComplete={(color) => setStroke(color)} />
						</FormControl>
					</Grid>

					{/* <Grid xs={6}>
                        <FormControl>
                            <FormLabel>Estilo</FormLabel>
                            <ToggleButtonGroup variant="outlined" onChange={(evt: any) => { setTextStyle(evt.target.value) }} value={textStyle}>
                                <IconButton value="bold">
                                    <span className="material-symbols-rounded">
                                        format_bold
                                    </span>
                                </IconButton>
                                <IconButton value="italic">
                                    <span className="material-symbols-rounded">
                                        format_italic
                                    </span>
                                </IconButton>
                                <IconButton value="underlined">
                                    <span className="material-symbols-rounded">
                                        format_underlined
                                    </span>
                                </IconButton>
                            </ToggleButtonGroup>
                        </FormControl>
                    </Grid> */}

					<Grid xs={4} />
					<Grid xs={4}>
						<Button onClick={handleTextSave} fullWidth={true}>
							Adicionar
						</Button>
					</Grid>
					<Grid xs={4} />
				</Grid>
			</form>
		</div>
	);
}
