import collectionCard from "../interfaces/collectionCard";
import apiClient from "../plugins/apiClient";
import { fetchAuthSession } from 'aws-amplify/auth';

export async function getProjects() {
    const info = await fetchAuthSession();
    if(info.userSub == null) {
        let local = localGetProjectByID()
        if(local){
            return [local]
        }else{
            return []
        }
    }
    try {
        const response = await apiClient.get( `/customer/@userID/project`);
        const data = response.data;
        return data
    } catch (error) {
        console.error(error);
    }
}

export async function getProjectByID(projectID: collectionCard["Id"]) {
    const info = await fetchAuthSession();
    if(info.userSub == null || projectID === "convidado") {
        return localGetProjectByID();
    }
    try {
        const response = await apiClient.get(`/customer/@userID/project/${projectID}`)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

export async function newProject(project: collectionCard) {
    const info = await fetchAuthSession();
    if(info.userSub == null) {
        return localNewProject(project);
    }
    try {
        const response = await apiClient.post(`/customer/@userID/project`, project);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export async function editProject(projectID: collectionCard["Id"], project: collectionCard) {
    const info = await fetchAuthSession();
    if(info.userSub == null) {
        return localNewProject(project);
    }
    if(projectID === "convidado"){
        project.Id = null;
        sessionStorage.removeItem('localProject')
        return newProject(project)
    }
    try {
        const response = await apiClient.put(`/customer/@userID/project/${projectID}`, project)
        return response.data
    } catch (error) {
        console.error(error)
    }
}

export async function deleteProject(projectID: collectionCard["Id"]) {
    try {
        const response = await apiClient.delete(`/customer/@userID/project/${projectID}`);
        return response.data
    } catch (error) {
        console.error(error)
    }
}

//FUNCTION TO SAVE ON LOCALSTORAGE IN CASE USER IS NOT LOGGED IN
function localNewProject(localProject: collectionCard) {
    localProject.Id = "convidado";
    sessionStorage.setItem('localProject', JSON.stringify(localProject))
    return localProject;
}

function localGetProjectByID() {
    let project = sessionStorage.getItem('localProject')
    return JSON.parse(project);
}