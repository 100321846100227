import { useContext, Suspense } from "react";
import EmojiPicker, { EmojiClickData, EmojiStyle } from "emoji-picker-react";
import Element from "../../../../interfaces/element";
import { ElementsContext } from "../../../../pages/create/create";
import "./emoji_menu.scss";
import { v1 as uuidV1 } from "uuid";
import { CircularProgress } from "@mui/joy";
import ChangeSetSingleton from "../../texture_renderer/ChangeSetSingleton";

export default function EmojiMenu() {
	const { elements, setElements } = useContext(ElementsContext);
	const addEmoji = (emoji: EmojiClickData, event: MouseEvent) => {
		const newElement: Element = {
			id: uuidV1(),
			media: {
				src: emoji.imageUrl,
			},
			x: 300,
			y: 300,
			type: "emoji",
			scaleX: 2,
			scaleY: 2,
			shown: true,
			zIndex: elements.length + 1,
			rotation: 0,
		};
		setElements((els)=>{
			ChangeSetSingleton.get().addHistory([newElement, ...els])
			return [newElement,...els]
		});
	};
	return (
		<div className="emojis">
			<Suspense fallback={<CircularProgress />}>
				<EmojiPicker
					searchDisabled={true}
					previewConfig={{ showPreview: false }}
					onEmojiClick={addEmoji}
					height={window.innerHeight * 0.6}
					emojiStyle={EmojiStyle.NATIVE}
				/>
			</Suspense>
		</div>
	);
}
