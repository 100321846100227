import mug from "../../assets/mug.png";
import mugs from "../../assets/mugs.png";
import cattoMug from "../../assets/cattoMug.png";
import backVideo from "../../assets/backVideo.mp4";
import mugVideo from "../../assets/mugCreationVideo.mp4";
import fizzLogo from "../../assets/logozz-color-white.png";

import Lottie from "react-lottie";
import supportData from "../../lotties/support.json";
import coWorkData from "../../lotties/co_workers.json";
import creativityData from "../../lotties/creativity.json";

import { Box, Button, Grid, Stack, Typography } from "@mui/joy";
import { Helmet } from "react-helmet";

import "./landing.scss";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import { mockData } from "../user/collection/collectionData";

import LoginMenu from "../../components/login_menu/LoginMenu";
import CardPreview from "../../components/global_components/CardPreview";
import SupportForm from "../../components/global_components/SupportForm";
import MobileDrawerMenu from "../../components/login_menu/MobileDrawerMenu";
import Slider from "../../components/global_components/Slider";
import { useUser } from "../../providers/UserInfo";
import Carousel from "../../components/global_components/Carousel";
import EmblaCarousel from "../../components/global_components/Carousel";
import { EmblaOptionsType } from 'embla-carousel'

function Landing() {
	const [productIndex, setProductIndex] = useState(0);
	const [isMobile, setIsMobile] = useState<boolean>(false);
	const [continueProject, setContinueProject] = useState(JSON.parse(sessionStorage.getItem("localProject")));


	const carouselOptions: EmblaOptionsType = { loop: true }
	const test = 5;
	const carouselSlides = Array.from(Array(test).keys())

	const user = useUser();
	const nav = useNavigate();
	const myRef = useRef(null);

	const lottieOptionsData = (data) => {
		let options = {
			loop: true,
			autoplay: true,
			animationData: data,
			pause: false,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};
		return options;
	};

	const handleResize = () => {
		setIsMobile(window.innerWidth <= 768 ? true : false);
	};

	useEffect(() => {
		handleResize();
		setContinueProject(JSON.parse(sessionStorage.getItem("localProject")))

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	function handleTopMenu() {
		if (isMobile) {
			return (
				<Box className="headerTopBarMobile">
					<Link to="/" className="logoTop" style={{ margin: "0", height: "2em" }}>
						<img src={fizzLogo} style={{ height: "2em" }} alt="Fizz logo" />
					</Link>
					{!user.user && (
						<>
							<Link className="menuLink" to="/login">
								Entrar
							</Link>
							<Button size="lg" onClick={() => nav("/login")}>
								Cadastre-se
							</Button>
						</>
					)}
					{user.user && <MobileDrawerMenu color="primary" variant="solid" />}
				</Box>
			);
		}
		if (!isMobile) {
			return (
				<div className="headerTopBar">
					<div className="topWrapper">
						<Link to="/" className="logoTop">
							<img src={fizzLogo} style={{ height: "64px" }} alt="fizz logo" />
						</Link>
						<Box display="flex" gap="1em" justifyContent="flex-end" alignContent="flex-end">
							<Link className="menuLink" to={continueProject ? "/criar/convidado" : "/criar"}>
								{continueProject ? "Continuar" : "Criar"}
							</Link>
							{/* <Link className="menuLink" to="/explorar"> Explorar </Link> */}
							{!user.user && (
								<Link className="menuLink" to="/login">
									Entrar
								</Link>
							)}
							{user.user && (
								<Link className="menuLink" to="/colecao">
									Coleção
								</Link>
							)}
							{!user.user && (
								<Button onClick={() => nav("/login")} sx={{ width: "auto" }}>
									Cadastre-se
								</Button>
							)}
							{user.user && <LoginMenu color="primary" variant="solid" />}
						</Box>
					</div>
				</div>
			);
		}
	}

	useEffect(() => {
		setTimeout(() => {
			if (productIndex < 2) {
				setProductIndex(productIndex + 1);
			} else {
				setProductIndex(0);
			}
		}, 3000);
	}, [setProductIndex, productIndex]);

	return (
		<div className="snapWrapper">
			<Helmet>
				<title>Fizz Online</title>
			</Helmet>
			<header className="landingHeader snapChild">
				<video id="backgroundVideo" loop autoPlay muted disablePictureInPicture playsInline>
					<source src={backVideo} type="video/mp4" />
				</video>
				{handleTopMenu()}
				<div className="homeWrapper">
					<Grid container spacing={2} padding="2em">
						<Grid xs={12}>
							<div className="headerContent">
								<h1 className="headText">Editor de alta fidelidade para suas canecas personalizadas</h1>
								<ul className="productImage">
									<li>
										<img src={mug} alt="section" className={productIndex === 0 ? "show" : ""}></img>
									</li>
									<li>
										<img src={cattoMug} alt="section" className={productIndex === 1 ? "show" : ""}></img>
									</li>
									<li>
										<img src={mugs} alt="section" className={productIndex === 2 ? "show" : ""}></img>
									</li>
								</ul>
								<Button
									onClick={() => nav(continueProject ? '/criar/convidado' : '/criar')}
									sx={{ width: "auto", gridArea: "buttons", fontSize: "1.5em" }}
									size="xl"
								>
									{continueProject ? 'Continuar personalização' : 'Começar personalização'}
								</Button>
								<a href="/#"
									onClick={() => myRef.current.scrollIntoView({ block: "center", behavior: "smooth" })}
									style={{ gridArea: "saiba", textAlign: "end", cursor: "pointer" }}
								>
									Saiba mais
								</a>
							</div>
						</Grid>
					</Grid>
				</div>
			</header>

			<main className="snapChild" id="main">
				<Stack>
					<div className="homeWrapper" style={{ backgroundColor: "var(--primary)" }}>
						<Grid container padding="2em" ref={myRef} spacing={{ xs: 2, md: 0 }}>
							<Grid md={4} xs={12} container>
								<Grid md={12} xs={12} justifyContent={{ xs: "center", md: "flex-start" }}>
									<Typography level="h1" textColor="white" marginBottom="0.5em">
										Seja você mesmo
									</Typography>
									<Typography level="title-lg" textColor="white">
										Com o nosso editor para criar canecas que sejam do seu jeito!
										<br />
										Adicione imagens, textos e até figurinhas. As possibilidades são infinitas!
									</Typography>
								</Grid>
								<Grid md={12} xs={12}>
									<Lottie options={lottieOptionsData(creativityData)} width={"100%"} isClickToPauseDisabled={true} />
								</Grid>
							</Grid>
							<Grid md={1} />
							<Grid md={7} xs={12} sx={{ display: "flex", alignItems: "center" }}>
								<Box className="editorVideo">
									<video
										loop
										autoPlay
										muted
										disablePictureInPicture
										playsInline
										style={{ maxWidth: "100%", borderRadius: "1rem" }}
										onClick={() => nav("/criar")}
									>
										<source src={mugVideo} type="video/mp4" />
									</video>
								</Box>
							</Grid>
							<Grid md={1} />
						</Grid>
					</div>
					<div className="homeWrapper">
						<Grid container minHeight="670px" padding="2em" spacing={{ xs: 2, md: 0 }}>
							<Grid md={12} xs={12} container justifyContent="center">
								<Typography level="h1" textAlign="center">
									Criado por nossa comunidade
								</Typography>
							</Grid>

							<Grid md={12} xs={0} />
							<Grid md={12} xs={12} container justifyContent="center">
								{/* {isMobile && (
                  <Grid
                    xs={12}
                    sx={{
                      overflowY: "scroll",
                      display: "flex",
                      flexDirection: "column",
                      gap: "1em",
                      padding: "0.5em",
                      maxHeight: "50vh",
                    }}
                  >
                    {mockData.map((item) => {
                      return (
                        <Box
                          key={item.id}
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          <CardPreview
                            imgSrc={cattoMug}
                            showFavorite={false}
                            id={item.id}
                            title={item.title}
                            menu={false}
                          />
                        </Box>
                      );
                    })}
                  </Grid>
                )} */}
								<Grid md={12} sx={{ position: "relative" }}>
									{/* <Slider>
										{mockData.map((item) => {
											return (
												<Box key={item.id}>
													<CardPreview
														id={item.id}
														width="300px"
														height="300px"
														title={item.title}
														imgSrc={cattoMug}
														showFavorite={false}
														menu={false}
													/>
												</Box>
											);
										})}
									</Slider> */}
									<EmblaCarousel options={carouselOptions} slides={mockData.map((item) => {
										return (
											<Box key={item.title}>
												<CardPreview
													id={item.id}
													width="300px"
													height="300px"
													title={item.title}
													imgSrc={cattoMug}
													showFavorite={false}
													menu={false}
												/>
											</Box>
										);
									})} />
								</Grid>
							</Grid>
							<Grid md={12} xs={12} />

							<Grid
								md={12}
								xs={12}
								sx={{
									justifyContent: "center",
									display: "flex",
									marginBottom: "1em",
									textAlign: "center",
								}}
							>
								<Typography level="h3">
									Monte sua caneca também.
									<br />É de graça e sem compromisso!
								</Typography>
							</Grid>
							<Grid md={4} xs={12} />
							<Grid md={4} xs={12} sx={{ justifyContent: "center", display: "flex" }}>
								{/* <Button size="xl" onClick={() => nav("/explorar")}>
									Explorar mais modelos
								</Button> */}
							</Grid>
							<Grid md={4} xs={12} />
						</Grid>
					</div>
					<div className="homeWrapper" style={{ backgroundColor: "var(--accent)" }}>
						{isMobile && (
							<Grid container spacing={{ xs: 2, md: 0 }}>
								<Grid md={6} xs={12} container marginBottom="1em">
									<Grid md={12} xs={12} sx={{ display: "flex", justifyContent: "center" }}>
										<Lottie
											options={lottieOptionsData(coWorkData)}
											height={300}
											width={300}
											isClickToPauseDisabled={true}
											style={{ margin: "0" }}
										/>
									</Grid>
								</Grid>
								<Grid md={4} xs={12} container>
									<Grid md={0} xs={1} />
									<Grid
										md={12}
										xs={10}
										container
										justifyContent={{ md: "flex-end", xs: "center" }}
										padding={{ md: "0", xs: "1em" }}
										gap={{ md: 0, xs: 2 }}
									>
										<Typography level="h1" textColor="white" textAlign={isMobile ? "center" : "justify"}>
											Produz canecas?
										</Typography>
										<Typography level="h4" textColor="white" textAlign={isMobile ? "center" : "justify"}>
											Trabalhamos com parcerias, vamos crescer juntos!
										</Typography>
										<Typography level="h4" textColor="white" textAlign={isMobile ? "center" : "end"}>
											Seja usando nosso editor para seus clientes ou produzindo canecas que nossos clientes criaram
										</Typography>
									</Grid>
									<Grid md={0} xs={1} />

									<Grid md={12} xs={12} />
									<Grid
										md={12}
										xs={12}
										container
										justifyContent={{ md: "flex-end", xs: "center" }}
										alignSelf={{ md: "flex-start" }}
										marginBottom={{ md: 0, xs: "1em" }}
									>
										<Button size="xl" onClick={() => nav("/parceria")}>
											Como funciona?
										</Button>
									</Grid>
								</Grid>

								<Grid
									md={12}
									xs={12}
									container
									padding="2em"
									sx={{
										backgroundColor: "var(--primary)",
										borderTopLeftRadius: "1rem",
										borderTopRightRadius: "1rem",
									}}
								>
									<Grid md={6} xs={12} container>
										<Grid md={12} xs={12}>
											<Typography level="h1" textColor="white">
												Faltou explicar algo ou quer bater um papo?
											</Typography>
											<Typography level="body-lg" textColor="white">
												Entre em contato conosco nos nossos canais de atendimento
											</Typography>
										</Grid>
										<Grid md={12} xs={12} sx={{ margin: isMobile ? "0" : "3em" }} />
										<Grid md={12} xs={12}>
											<SupportForm
												className="support"
												emailPlaceholder="Seu e-mail"
												messagePlaceholder="Escreva sua mensagem aqui"
												buttonText="Enviar e-mail"
												maxWidth={isMobile ? "100%" : "70%"}
											/>
										</Grid>
									</Grid>
									<Grid md={6} xs={12}>
										<Lottie options={lottieOptionsData(supportData)} width={"100%"} isClickToPauseDisabled={true} />
									</Grid>
								</Grid>
							</Grid>
						)}
					</div>
					<div className="homeWrapper" style={{ backgroundColor: "var(--accent)" }}>
						{!isMobile && (
							<Grid container spacing={{ xs: 2, md: 0 }} padding="2em 2em 0 2em">
								<Grid md={6} xs={12} container marginBottom="1em">
									<Grid md={12} xs={12} sx={{ display: "flex", justifyContent: "flex-start" }}>
										<Lottie
											options={lottieOptionsData(coWorkData)}
											height={400}
											width={400}
											isClickToPauseDisabled={true}
											// style={{ margin: "0" }}
										/>
									</Grid>
								</Grid>
								<Grid md={6} xs={12} container>
									<Grid
										md={12}
										xs={12}
										container
										justifyContent={{ md: "flex-end", xs: "center" }}
										padding={{ md: "0", xs: "1em" }}
										marginTop="5em"
										gap={{ md: 0, xs: 2 }}
									>
										<Typography level="h1" textColor="white" textAlign={isMobile ? "center" : "justify"}>
											Produz canecas?
										</Typography>
										<Typography level="h4" textColor="white" textAlign={isMobile ? "center" : "justify"}>
											Trabalhamos com parcerias, vamos crescer juntos!
										</Typography>
										<Typography level="h4" textColor="white" textAlign={isMobile ? "center" : "end"}>
											Seja usando nosso editor para seus clientes ou produzindo canecas que nossos clientes criaram
										</Typography>
									</Grid>
									<Grid md={12} xs={12} />
									<Grid
										md={12}
										xs={12}
										container
										justifyContent={{ md: "flex-end", xs: "center" }}
										alignSelf={{ md: "flex-start" }}
										marginBottom={{ md: 0, xs: "1em" }}
									>
										<Button size="xl" onClick={() => nav("/parceria")}>
											Como funcionam as parcerias
										</Button>
									</Grid>
								</Grid>

								<Grid
									md={12}
									xs={12}
									container
									sx={{
										backgroundColor: "var(--primary)",
										padding: "2em",
										borderTopLeftRadius: "1rem",
										borderTopRightRadius: "1rem",
									}}
								>
									<Grid md={6} xs={12} container>
										<Grid md={12} xs={12}>
											<Typography level="h1" textColor="white">
												Faltou explicar algo ou quer bater um papo?
											</Typography>
											<Typography level="body-lg" textColor="white">
												Entre em contato conosco nos nossos canais de atendimento
											</Typography>
										</Grid>
										<Grid md={12} xs={12} sx={{ margin: isMobile ? "0" : "3em" }} />
										<Grid md={12} xs={12}>
											<SupportForm
												className="support"
												emailPlaceholder="Seu e-mail"
												messagePlaceholder="Escreva sua mensagem aqui"
												buttonText="Enviar e-mail"
												maxWidth={isMobile ? "100%" : "70%"}
											/>
										</Grid>
									</Grid>
									<Grid md={6} xs={12}>
										<Lottie options={lottieOptionsData(supportData)} width={"100%"} isClickToPauseDisabled={true} />
									</Grid>
								</Grid>
							</Grid>
						)}
					</div>
				</Stack>
			</main>

			<footer className="snapChild">
				<Typography level="title-lg" textColor="white">
					Fizz, um produto João de Barro
				</Typography>
			</footer>
		</div>
	);
}

export default Landing;
